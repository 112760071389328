<template>
  <div id="app" class="h-screen flex flex-wrap content-center justify-center bg-gray-500
    bg-gradient-to-r from-green-400 to-blue-500">
    <div class="text-4xl font-bold italic text-white">Lincepro.es</div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
</style>
